<template>
  <div class="qgroup-form display--flex">
    <div class="card qgroup-form__staff">
      <div class="card-header card-header-divider">Группа вопросов</div>
      <div class="card-body">
        <form action="#" @submit.prevent="submitQuestionGroup" novalidate>
          <div class="qgroup-form__inputs display--flex justify-content-center">
            <div class="qgroup-form__col1">
              <custom-input
                label="Текст"
                name="text"
                req
                v-model="form.text"
              ></custom-input>
              <custom-select-2
                label="Вид"
                name="view"
                v-model="form.view"
                :options="viewOptions"
              />
              <preview-file-loader
                :image="form.image"
                @loadPhoto="loadPhoto"
                label="Картинка"
                name="image"
              />
            </div>
          </div>
          <div
            class="
              qgroup-form__btns
              mt-25
              mb-10
              display--flex
              justify-content-end
            "
          >
            <button type="submit" class="btn btn-accent mr-10">
              Сохранить
            </button>
            <router-link to="/test/question-group" class="btn btn-w"
              >Назад</router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import useErrors from "../../../mixins/useErrors";
import { reactive, ref } from "@vue/reactivity";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { computed, onBeforeMount } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import CustomInput from "../../../components/Forms/Fields/CustomInput.vue";
import PreviewFileLoader from "../../../components/Forms/Fields/PreviewFileLoader.vue";
import standartError from "../../../mixins/standartError";

export default {
  components: { CustomSelect2, CustomInput, PreviewFileLoader },
  name: "qgroup-form",
  setup() {
    const store = useStore(),
      route = useRoute(),
      { setErrors, clearCustomErrors } = useErrors();
    const form = reactive({
      text: "",
      view: "table",
      image: null,
    });
    const viewOptions = ref([
      { id: "table", text: "Таблица" },
      { id: "tabs", text: "История" },
      { id: "list", text: "Список" },
      { id: "icons", text: "Иконки" },
    ]);
    const questionGroup = computed(() => store.state.test.questionGroup);

    onBeforeMount(() => {
      if (route.params.id) {
        store.dispatch("test/getQuestionGroup", route.params.id).then(() => {
          form.text = questionGroup.value.text;
          form.view = questionGroup.value.view;
          form.image = questionGroup.value.image;
        });
      }
    });

    let submitQuestionGroup = () => {
      if (route.params.id) {
        store
          .dispatch("test/updateQuestionGroup", route.params.id, { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      } else {
        store
          .dispatch("test/createQuestionGroup", { ...form })
          .catch(standartError(setErrors, clearCustomErrors));
      }
    };
    const loadPhoto = (image) => {
      form.image = image;
    };

    return {
      form,
      submitQuestionGroup,
      viewOptions,
      questionGroup,
      loadPhoto,
    };
  },
};
</script>

<style lang="scss" scoped>
.qgroup-form {
  padding: 0 0 10px 0;
  display: flex;
  width: 85%;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  .form-group {
    margin: 35px -15px;
  }

  .card-body {
    padding: 20px 50px;
  }

  &__col1 {
    flex: 1;
  }

  @media (max-width: 1360px) {
    width: 100%;
  }

  @media (max-width: 973px) {
    .form-group {
      margin: 15px -15px;
    }
  }
}
</style>